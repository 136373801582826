import { useCallback, useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import useTombFinance from './useTombFinance';
import { Contract } from 'ethers';
import config from '../config';

const useContractLockedSum = (token: Contract) => {
  const [lockedSum, setLockedSum] = useState(0);

  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;
  const fetchBalance = useCallback(async () => {
    if (token) {
      const count = await token.epochCount();
      let sum = 0.00;
      for (var i = 0; i < Number(count); i++) {
        let lockedAmount;
        try {
          lockedAmount = await token.userLocks(tombFinance?.myAccount, BigNumber.from(i));
          lockedAmount = Number(lockedAmount[0]) / 10 ** 18;
        } catch (error) {
          lockedAmount = 0;
        }
        sum += lockedAmount;
      }
      setLockedSum(sum);
    }
  }, [token, tombFinance?.myAccount]);

  useEffect(() => {
    if (isUnlocked) {
      fetchBalance().catch((err) => console.error(`Failed to fetch token balance: ${err.stack}`));
      let refreshInterval = setInterval(fetchBalance, config.refreshInterval);
      return () => clearInterval(refreshInterval);
    }
  }, [isUnlocked, token, fetchBalance, tombFinance]);

  return lockedSum;
};

export default useContractLockedSum;
