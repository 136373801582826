import React from 'react';
import { Container } from '@material-ui/core';
import useEagerConnect from '../../hooks/useEagerConnect';

import Nav from '../Nav'
import NavSidebar from '../NavSidebar';
import NavSimplified from '../NavSimplified';
import styled from 'styled-components';
import FooterV2 from '../FooterV2';
import Hero from '../../views/Home/components/Hero';
import HeroV2 from '../../views/Cemetery/Hero';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containPage: {
    // backgroundImage: `url('./assets/background-images/day-background.svg')`,
    // backgroundRepeat: 'no-repeat',
    // height: `calc(100vh - 97px - 134px)`,
    marginTop: '7px !important',
    paddingTop: '20px',
    backgroundSize: 'contain',
    paddingBottom: '157px',
    backgroundPosition: 'top',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'inherit !important',
      // height: `calc(100vh - 81px - 81px)`,
      backgroundSize: 'auto',
      paddingTop: '23px',
      paddingBottom: '110px',
      paddingLeft: '8px',
      paddingRight: '8px'
    },
  }
}));


const StyledContainer = styled(Container)`

`
interface HeroProps {
  showHero?: boolean
  showHeroPages?: boolean
  childrenHero?: boolean
}
const Page: React.FC<HeroProps> = ({ children, childrenHero, showHero, showHeroPages }) => {
  useEagerConnect();
  const classes = useStyles();

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Nav />
      {showHero && <Hero />}
      {showHeroPages && <HeroV2 childrenHero={childrenHero} />}
      <StyledContainer className={classes.containPage} maxWidth="lg">
        {children}
      </StyledContainer>
      <FooterV2 />
    </div>
  );
};

export default Page;
