import React, { useMemo } from 'react';
import { Box, Button, CardContent, Container, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import HeroBg from '../../assets/img/aera-home.webp';
import HeroBg2 from '../../assets/img/aera-home-gold.png';
import { tomb as tombProd, tShare as tShareProd } from '../../tomb-finance/deployments/deployments.mainnet.json';
import CardPyramid from '../../components/CardPyramid';

const Background = styled.div`
  ${localStorage.getItem('theme-aera') === 'theme-aera-day' &&
  `
`}
  ${localStorage.getItem('theme-aera') === 'theme-aera-night' &&
  `
`}
  background-position: center;
  width: 100%;
  height: 45vh;
  margin-top: -4rem !important;

  @media (max-width: 960px) {
    margin-top: 0px !important;
  }

  @media (max-width: 650px) {
    margin-top: -4rem !important;
  }
`;

const StyledGridLeft = styled(Grid)`
  margin-top: -6rem !important;

  @media (max-width: 960px) {
    margin-top: 0px !important;
  }
`;

const StyledGrid = styled(Grid)`
  // padding: 3rem;
  height: 100%;
  align-content: center;
`;

const HeaderText = styled(Typography)`
  ${(localStorage.getItem('theme-aera') === null || localStorage.getItem('theme-aera') === 'theme-aera-day') &&
  `
color: #fcfcfc !important;
`}
  ${localStorage.getItem('theme-aera') === 'theme-aera-night' &&
  `
color: #fcfcfc !important;
`}
  @media(max-width: 960px) {
    text-align: left;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

const ButtonGrid = styled(Grid)`
  @media (max-width: 650px) {
    place-content: center;
  }
`;

interface HeroProps {
  childrenHero?: boolean
}
const Hero: React.FC<HeroProps> = ({ childrenHero }) => {
  let tomb;
  tomb = tombProd;
  const buyTombAddress = 'https://swap.spiritswap.finance/#/exchange/swap/' + tomb.address;

  return (
    <Background>
      <Container maxWidth="lg" style={{ height: '100%' }}>
        {childrenHero}
      </Container>
    </Background>
  );
};

export default Hero;
