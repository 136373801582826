import { useCallback, useEffect, useState } from 'react';
import { BigNumber, Contract } from 'ethers';
import useTombFinance from './useTombFinance';
import config from '../config';

const useTokenIdByOwner = (contract: Contract) => {
  const [tokenId, setTokenId] = useState(BigNumber.from(0));
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;
  const fetchTokenId = useCallback(async () => {
    if (contract) {
      setTokenId(await contract.tokenOfOwnerByIndex(tombFinance?.myAccount, BigNumber.from(0)));
    }
  }, [contract, tombFinance]);

  useEffect(() => {
    if (isUnlocked) {
      fetchTokenId().catch((err) => console.error(`Failed to fetch contract balance 2: ${err.stack}`));
      let refreshInterval = setInterval(fetchTokenId, config.refreshInterval);
      return () => clearInterval(refreshInterval);
    }
  }, [isUnlocked, contract, fetchTokenId, tombFinance, tokenId]);

  return tokenId;
};

export default useTokenIdByOwner;
