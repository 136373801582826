import React, { useCallback, useEffect, useState } from 'react';
import Context from './context';
import useTombFinance from '../../hooks/useTombFinance';
import { Casino } from '../../tomb-finance';
import config, { casinosDefinitions } from '../../config';

const Casinos: React.FC = ({ children }) => {
  const [casinos, setCasinos] = useState<Casino[]>([]);
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;

  const fetchPools = useCallback(async () => {
    const casinos: Casino[] = [];

    for (const casinoInfo of Object.values(casinosDefinitions)) {
      if (casinoInfo.finished) {
        if (!tombFinance.isUnlocked) continue;

        // only show pools staked by user
        // const balance = await tombFinance.stakedBalanceOnCasino(
        //   casinoInfo.contract,
        //   casinoInfo.poolId,
        //   tombFinance.myAccount,
        // );
        // if (balance.lte(0)) {
        //   continue;
        // }
      }
      casinos.push({
        ...casinoInfo,
        address: config.deployments[casinoInfo.contract].address,
        depositToken: casinoInfo.depositTokenName === 'SCARAB' || casinoInfo.depositTokenName === 'GSCARAB' ? (casinoInfo.depositTokenName === 'SCARAB' ? tombFinance.SCARAB : tombFinance.GSCARAB) : tombFinance.externalTokens[casinoInfo.depositTokenName]
      });
    }
    casinos.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setCasinos(casinos);
  }, [tombFinance, setCasinos]);

  useEffect(() => {
    if (tombFinance) {
      fetchPools().catch((err) => console.error(`Failed to fetch casinos: ${err.stack}`));
    }
  }, [isUnlocked, tombFinance, fetchPools]);

  return <Context.Provider value={{ casinos }}>{children}</Context.Provider>;
};

export default Casinos;
