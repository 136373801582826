import { useCallback, useMemo, useEffect, useState } from 'react';
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks';
import useAllowance from './useAllowance';
import ERC20 from '../tomb-finance/ERC20';
import { parseEther } from 'ethers/lib/utils';
import useTombFinance from './useTombFinance';
import { BigNumber, Contract, ethers } from 'ethers';
import useHandleTransactionReceipt from './useHandleTransactionReceipt';
import useTokenBalance from './useTokenBalance';
import useTokenIdByOwner from './useTokenIdByOwner';
import useGetApproveAddr from './useGetApproveAddr';

const APPROVE_BASE_AMOUNT = parseEther('0.0001');
const APPROVE_BASE_TOKENID = BigNumber.from('0');

const APPROVE_AMOUNT = ethers.constants.MaxUint256;

export enum ApprovalStateNFT {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApprove(contract: Contract, spender: string): [ApprovalStateNFT, () => Promise<void>] {
  const tombFinance = useTombFinance();
  const [successState, setSuccessState] = useState(false);
  const addr = useGetApproveAddr(contract);
  const tokenId = useTokenIdByOwner(contract);

  // check the current approval status
  const approvalState: ApprovalStateNFT = useMemo(() => {
    // we might not have enough data to know whether or not we need to approve
    if (addr == null) return ApprovalStateNFT.UNKNOWN;
    // amountToApprove will be defined if currentAllowance is
    return addr === spender ? ApprovalStateNFT.APPROVED : ApprovalStateNFT.NOT_APPROVED;
  }, [addr, successState]);

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const approve = useCallback(
    async (): Promise<void> => {
      console.log("this is NFT approve function.", tokenId);

      if (Number(tokenId) != 0) {
        handleTransactionReceipt(
          contract.approve(spender, tokenId),
          `Approve ${tokenId} Fractal`,
        );

      }
    },
    [approvalState, contract, spender, handleTransactionReceipt, tokenId],
  );

  return [approvalState, approve];
}

export default useApprove;
