import React, { useMemo } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Pharaoh from '../../../assets/img/homeimga.png';
import HeroBg2 from '../../../assets/img/aera-home-gold.png'
import { tomb as tombProd, tShare as tShareProd } from '../../../tomb-finance/deployments/deployments.mainnet.json';
  
const Background = styled.div`
${localStorage.getItem('theme-aera') === 'theme-aera-day' && (`
`)}
${localStorage.getItem('theme-aera') === 'theme-aera-night' && (`
`)}
background-size: cover;
background-position: center;
width: 100%;
height: 80vh;
margin-top: -4rem !important;

@media(max-width: 960px) {
  margin-top: 0px !important;
}

@media(max-width: 650px) {
  margin-top: -4rem !important;
}
`

const StyledGridLeft = styled(Grid)`
margin-top: -6rem !important;
padding-top: 90px !important;

@media(max-width: 960px) {
  margin-top: 0px !important;
}
`

const StyledGridRight = styled(Grid)`

& > img {
  margin-top: -6rem !important;
  @media(max-width: 960px) {
    margin-top: 0px !important;
  }

  @media(max-width: 650px) {
    display: none;
  }
}

`

const StyledGrid = styled(Grid)`
// padding: 3rem;
height: 100%;
padding-top: 120px !important;
align-content: center;
`

const HeaderText = styled(Typography)`
${(localStorage.getItem('theme-aera') === null || localStorage.getItem('theme-aera') === "theme-aera-day") && 
(`
color: #fcfcfc !important;
`)}
${localStorage.getItem('theme-aera') === "theme-aera-night" && 
(`
color: #fcfcfc !important;
`)}
  @media(max-width: 960px) {
    text-align: left;
  }

  @media(max-width: 650px) {
    text-align: center;
  }
`

const ButtonGrid = styled(Grid)`

  @media(max-width: 650px) {
    place-content: center;
  }
`

// const ButtonPyramid = styled(Button)`
// text-shadow: none !important;

// ${localStorage.getItem('theme-aera') === "theme-aera-3" && 
// (`
// background-color: rgba(100,100,19,0.2) !important;
// border: 2px solid rgba(100,100,19,0.4) !important;
// color: #fff !important;

// &:hover {
//   background: rgba(100,100,19,0.4) !important;
//   border: 2px solid rgba(100,100,19,0.6) !important;
// }`)}

// ${localStorage.getItem('theme-aera') === "theme-aera-4" && 
// (`
// background-color: rgba(100,100,19,0.2) !important;
// border: 2px solid rgba(100,100,19,0.4) !important;
// color: #fff !important;

// &:hover {
//   background: rgba(100,100,19,0.4) !important;
//   border: 2px solid rgba(100,100,19,0.6) !important;
// }`)}
// `

const Hero: React.FC = () => {
  let tomb;
  tomb = tombProd;
  const buyTombAddress = 'https://swap.spiritswap.finance/#/exchange/swap/' + tomb.address;

    return (
      <Background>
        <Container maxWidth="lg" style={{height: '100%', paddingTop: "100px !important"}}>
        <StyledGrid container style={{paddingTop: "100px !important"}}>
          <StyledGridLeft item lg={7} md={7} xs={12} style={{paddingTop: '90px !important'}}>
            <Grid container style={{gridGap: '30px', paddingTop: '90px !important'}}>
              <HeaderText color='textPrimary' variant='h4'>
              Become a Yield Hunter<br/>
              with FluxZone
              </HeaderText>
              <HeaderText color='textSecondary' variant='h6'>
              Put your crypto to work. Choose your farm, stake, and start building your passive income streams right away.
              </HeaderText>
              {/* <HeaderText color='textPrimary' variant='h3'>
                Boost Your Yields
              </HeaderText>
              <HeaderText color='textSecondary' variant='h5'>
              Stake, boost, and begin earning now<br></br>
              </HeaderText> */}
              <ButtonGrid container >
                <Grid item>
                  <HeaderText>
                <Button href='https://docs.fluxzone.xyz' target="_blank" variant="contained" style={{ marginRight: '10px', minWidth: '150px' }}>
                  Learn More
                </Button>
                </HeaderText>
                </Grid>
              </ButtonGrid>
            </Grid>
          </StyledGridLeft>
        <StyledGridRight item lg={5} md={5} xs={12} style={{textAlign: 'center', paddingTop: "20px" }}>
            <img src={Pharaoh} width={400} height={400}/>
    </StyledGridRight>
        </StyledGrid>
        </Container>
      </Background>
    );
  }

export default Hero;