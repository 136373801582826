import React from 'react';
import { Container } from '@material-ui/core';
import useEagerConnect from '../../hooks/useEagerConnect';

import Footer from '../Footer';
import Nav from '../Nav';
import PageV1 from './PageV1'
import PageV2 from './PageV2';
import PageV3 from './PageV3';

interface HeroProps {
  showHero?: boolean
  showHeroPages?: boolean
  childrenHero?: any
}
const Page: React.FC<HeroProps> = ({ children, showHero, showHeroPages, childrenHero }) => {
  useEagerConnect();
  return (
    // localStorage.getItem('theme-aera') === 'theme-aera-4' ? (<PageV2 children={children} />) : (<PageV1 children={children} />)
    // localStorage.getItem('theme-aera') === 'theme-aera-4' ? (<PageV3 children={children} showHero={showHero} />) : (<PageV1 children={children} />)
    <PageV3 children={children} showHero={showHero} showHeroPages={showHeroPages} childrenHero={childrenHero} />
  );
};

export default Page;
