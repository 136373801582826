function setTheme(themeName) {
  localStorage.setItem('theme-aera', themeName);
  document.documentElement.className = themeName;
}

function keepTheme() {
  if (localStorage.getItem('theme-aera')) {
    if (localStorage.getItem('theme-aera') === 'theme-aera-day') {
      setTheme('theme-aera-night');
    }
    if (localStorage.getItem('theme-aera') === 'theme-aera-night') {
      setTheme('theme-aera-night')
    }
  } else {
    setTheme('theme-aera-night')
  }
}

module.exports = {
  setTheme,
  keepTheme
}