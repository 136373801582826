import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useWallet } from 'use-wallet';
import useModal from '../../hooks/useModal';
import WalletProviderModal from '../WalletProviderModal';
import AccountModal from '../AccountModal';
import styled from 'styled-components';

interface AccountButtonProps {
  text?: string;
}

const StyleButton = styled(Button)`
  ${(localStorage.getItem('theme-aera') === null || localStorage.getItem('theme-aera') === 'theme-aera-day') &&
  `
  background: linear-gradient(180deg, #ffffff 0%, rgba(226, 67, 75, 0) 100%);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
  border-radius: 10px;
  color: #FCFCFC !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`}
  ${localStorage.getItem('theme-aera') === 'theme-aera-night' &&
  `
  background: linear-gradient(180deg, #ffffff 0%, rgba(226, 67, 75, 0) 100%);
  
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FCFCFC !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: #383030 !important;
  line-height: 24px;
`}

@media(max-width: 680px) {
  padding-top: 5px;
  padding-bottom: 5px;
}

`;

const StyleWalletButton = styled(Button)`
  @media(max-width: 680px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;


const AccountButton: React.FC<AccountButtonProps> = ({ text }) => {
  const { account } = useWallet();
  // const [onPresentAccountModal] = useModal(<AccountModal />);
  const [isWalletProviderOpen, setWalletProviderOpen] = useState(false);
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);

  const handleWalletProviderOpen = () => {
    setWalletProviderOpen(true);
  };

  const handleWalletProviderClose = () => {
    setWalletProviderOpen(false);
  };

  const handleAccountModalOpen = () => {
    setAccountModalOpen(true);
  };

  const handleAccountModalClose = () => {
    setAccountModalOpen(false);
  };


  const buttonText = text ? text : 'Unlock';

  return (
    <div>
      {!account ? (
        <StyleButton onClick={handleWalletProviderOpen} variant="contained">
          {buttonText}
        </StyleButton>
      ) : (
        <StyleWalletButton variant="contained" onClick={handleAccountModalOpen}>
          My Wallet
        </StyleWalletButton>
      )}

      <WalletProviderModal open={isWalletProviderOpen} handleClose={handleWalletProviderClose} />
      <AccountModal open={isAccountModalOpen} handleClose={handleAccountModalClose} />
    </div>
  );
};

export default AccountButton;
