import { useCallback } from 'react';
import useTombFinance from './useTombFinance';
import useHandleTransactionReceipt from './useHandleTransactionReceipt';
import { BigNumber, utils } from 'ethers';

const useTokenSwap = () => {
  const tombFinance = useTombFinance();
  const handleTransactionReceipt = useHandleTransactionReceipt();

  const ddd = tombFinance?.AERA.allowance(tombFinance?.myAccount, '0xe2A3D16EBb048205f2Fd918CE476A1ED2b7499f8');

  console.log('dddddddddddddd ', ddd);

  const handleSwap = useCallback(
    (amount: string) => {
      handleTransactionReceipt(
        tombFinance?.contracts['TokenSwap'].swap(utils.parseEther(amount)),
        `Convert ${amount} $AERA to ${amount} $ARA`,
      );
    },
    [tombFinance, handleTransactionReceipt],
  );
  return { onSwapAERAv1ToAERAv2: handleSwap };
};

export default useTokenSwap;
