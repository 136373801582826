import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import TwitterImage from '../../assets/img/twitter.svg';
import GscarabLogo from '../../assets/img/aerium-logo.png'
// import GithubImage from '../../assets/img/github.svg';
import TelegramImage from '../../assets/img/telegram.svg';
import DiscordImage from '../../assets/img/discord.svg';
import YoutubeImage from '../../assets/img/youtube.svg';
import ShopImg from '../../assets/img/shop-logo.svg';
import MediumImage from '../../assets/img/medium.svg';
import CertikImage from '../../assets/img/certiklogo.png';
import logo from '../../assets/logo.svg'
import logoNight from '../../assets/logo-night.svg'

import { black } from '../../theme/colors';

const StyledItem = styled(Grid)`
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: center
`

const StyledGrid = styled(Grid)`
  // [theme.breakpoints.down('xs')]: {
  //   flex-direction: column-reverse;
  //   display: flex;
  // },

`


const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'absolute',
    width: '100%',
    color: (localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#3F3F3F' : '#94A3B8'),
    textAlign: 'center',
    fontFamily: "Poppins !important",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    alignItems: 'center',
    borderTop: '1px solid #E2E8F0',
    /* identical to box height, or 150% */
    height: '133px !important',
    display: 'flex',
    top: 'initial',
    bottom: '0',
    [theme.breakpoints.down('xs')]: {
      height: '81px !important',
    },
  },
  link: {
    width: '60px',
    height: '24px',
    display: 'inline',
    marginLeft: '20px',
    fontFamily: "Poppins !important",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px !important',
    lineHeight: '24px',
    alignItems: 'center',
    color: (localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#3F3F3F' : '#94A3B8'),
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      alignItems: 'center',
      // margin: '0 auto !important'
    },

  },
  logoText: {
    fontFamily: "Poppins !important",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '48px',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      color: (localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#3F3F3F' : '#FFFFFF'),
    },

    /* or 240% */
  },
  img: {
    width: '24px',
    height: '24px',
  },
  footerRight: {
    textAlign: 'right',
    width: 'auto',
    display: 'inline-grid',

    alignItems: 'center',
    fontFamily: "Poppins !important",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  footerCenter: {
    display: 'inline-grid',
    textAlign: 'center',
    zIndex: 11
  },
  footerBackground: {
    background: 'radial-gradient(100% 1736.89% at 0% 67.32%, rgba(240, 9, 21, 0.7) 0%, rgba(226, 67, 75, 0.7) 29.69%, rgba(255, 92, 0, 0.7) 70.31%, rgba(255, 122, 0, 0.7) 100%)',
    filter: 'blur(102.5px)',
    transform: 'rotate(-180deg)',
    position: 'absolute',
    width: '414px',
    height: '97px',
    left: 'calc(50% - 207px)',
    [theme.breakpoints.down('xs')]: {
      left: 'calc(50% - 142px)',
      width: '284px',
    },
  },
  footerLogoContainer: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto'
    },
  },
  footerContain: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  footerLogo: {
    width: '32px',
    height: '32px',
    [theme.breakpoints.down('xs')]: {
      width: '24px',
      height: '24px',
    }
  }
}));

const FooterV2 = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <StyledGrid container className={classes.footerContain}>
          <StyledItem item sm={3} className={classes.footerLogoContainer}>
            <img className={classes.footerLogo} src={localStorage.getItem('theme-aera') === 'theme-aera-day' ? GscarabLogo : GscarabLogo} />
            <span className={classes.logoText}>FluxZone</span>
          </StyledItem>
          <StyledItem item sm={6} className={classes.footerCenter} >
            <div >
              <a
                href="https://twitter.com/FluxZ0ne"
                target="_blank"
                className={classes.link}
              >
                Twitter
              </a>

              <a href="https://discord.gg/DSaySuree8" to="#" target="_blank" className={classes.link}>
                Discord
              </a>
              <a
                href="https://docs.fluxzone.xyz"
                target="_blank"
                className={classes.link}
              >
                Docs
              </a>
            </div>
          </StyledItem>
          <StyledItem className={classes.footerBackground}>

          </StyledItem>

          <Grid item sm={3} className={classes.footerRight}>
            {/*   
            <a href="https://t.me/scarabfinance" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <img alt="telegram" src={TelegramImage} className={classes.img} />
            </a>*/}
            {/*  */}
            < div >
              {'Copyright © '}
              < Link color="inherit" href="/" >
                FluxZone
              </Link > {' '}
              {new Date().getFullYear()} {' '}
            </div >
            {/*   <a
              href="https://shop.scarab.finance/"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="shop" src={ShopImg} className={classes.img} />
            </a> 
 */}
          </Grid >
        </StyledGrid>
      </Container >
    </footer >
  );
};

export default FooterV2;
