import React, { /*useCallback, */ useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page';
import { makeStyles } from '@material-ui/core/styles';
import { createGlobalStyle, keyframes } from 'styled-components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import cx from 'classnames';
import UnlockWallet from '../../components/UnlockWallet';
import styled from 'styled-components';
import { /* Paper,*/ Button, Grid, Typography, TextField } from '@material-ui/core';

import redLogo from '../../assets/img/aerium2-logo.png';
import greyLogo from '../../assets/logo-grey.svg';
import useTokenBalance from '../../hooks/useTokenBalance';
import useTombFinance from '../../hooks/useTombFinance';
import { getDisplayBalance } from '../../utils/formatBalance';
import useApprove, { ApprovalState } from '../../hooks/useApproveNormal';
import useApproveNFT, { ApprovalStateNFT } from '../../hooks/useApproveNFT';
import useTokenSwap from '../../hooks/useTokenSwap';
import useSwapNFT from '../../hooks/useSwapNFT';

const BaseButton = styled(Button)({
  width: '90%',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '16px !important',
  padding: '6px 12px',
  border: '1px solid  !important',
  lineHeight: '28px  !important',
  backgroundColor: '#ffffff  !important',
  borderColor: '#000000 !important',
  color: '#fff !important',
  fontFamily: ['Poppins'].join(','),
  '&:hover': {
    backgroundColor: '#ffffff !important',
    color: '#fff !important',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#ffffff !important',
  },
});

const BlackBorderButton = styled(BaseButton)({
  borderColor: '#000 !important',
  textTransform: 'lowercase',
  '@media (max-width: 767px)': { // media query for screens with width smaller than 768px
    fontSize: '10px', // set font size for screens with width smaller than 768px
    fontWeight: 500,
    borderRadius: '8px !important'
  },

});

const WhiteBorderButton = styled(BaseButton)({
  borderColor: '#fff !important',
  textTransform: 'lowercase',
  '@media (max-width: 767px)': { // media query for screens with width smaller than 768px
    fontSize: '10px', // set font size for screens with width smaller than 768px
    fontWeight: 500,
    borderRadius: '8px'
  },
});

const BaseTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: 'auto',
    backgroundColor: 'transparent', // sets background color of input field
    borderRadius: '10px', // sets border radius of input field
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'none', // sets border color of input field outline
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'blue', // sets border color of input field outline when hovered
  },
  '& .MuiInputLabel-root': {
    color: 'green', // sets color of input field label
  },
  '& .MuiInput-underline:after, & .MuiInput-underline:before': {
    content: 'none',
  },
}));

const CustomWhiteTextField = styled(BaseTextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: 'white !important',
    '@media (max-width: 767px)': { // media query for screens with width smaller than 768px
      fontSize: '10px', // set font size for screens with width smaller than 768px
    },
  },
}));

const CustomBlackTextField = styled(BaseTextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: 'black !important',
    '@media (max-width: 767px)': { // media query for screens with width smaller than 768px
      fontSize: '10px', // set font size for screens with width smaller than 768px
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '150px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '10px',
    },
  },
  headerTitle: {
    maxWidth: '655px',
    fontSize: '16px',
    fontFamily: "Poppins !important",
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: 0,
    textAlign: 'center',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      paddingLeft: '10px',
      paddingRight: '10px'
    },
  },
  contentBlock: {
    maxWidth: '483px',
    width: '483px',
    minHeight: '400px',
    marginTop: '34px',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '7px',
      marginTop: '7px',
      marginRight: '7px',
    },

  },
  contenBlockTop: {
    maxWidth: '483px',
    width: '483px',
    marginTop: '34px',
    minHeight: '400px',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '7px',
      marginTop: '0px',
      marginRight: '7px',
    },
  },
  contentHeader: {
    fontFamily: "Poppins !important",
    fontSize: '21px',
    fontWeight: 400,
    lineHeight: '33px',
    letterSpacing: '0em',
    textAlign: 'left',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      paddingLeft: '0px'
    }
  },
  contentFooter: {
    width: '90%',
    fontFamily: "Poppins !important",
    margin: 'auto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'center',
    paddingBottom: '33px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      paddingBottom: '23px',
    }
  },
  contentTitle: {
    fontFamily: "Poppins !important",
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0em',
    text: 'center',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  contentBody: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    padding: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  rightSection: {
    paddingLeft: '26px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingTop: '12px',
    },
  },
  centeredText: {
    textAlign: 'center',
  },
  rightedText: {
    textAlign: 'right',
  },
  leftedText: {
    textAlign: 'left',
  },
  maxButton: {
    backgroundColor: 'transparent',
    width: '47px',
    height: '25px',
    fontFamily: "Poppins !important",
    fontStyle: 'normal',
    fontWeight: 550,
    fontSize: '12px',
    marginRight: '10px',
    color: '#1E293B',
    minWidth: '47px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      minWidth: '34px',
      fontSize: '10px',
      width: '34px',
      height: '16px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'initial !important',
    },
  },
  lazyLogo: {
    marginRight: '10px',
    height: '32px',
    [theme.breakpoints.down('xs')]: {
      height: '21px',
      marginRight: '7px',
    }
  },
  balanceBox: {
    textAlign: 'center',
    border: `${localStorage.getItem('theme-aera') === 'theme-aera-day' ? '1px solid #FFFFFF' : '1px solid #FFFFFF'}`,
    borderRadius: '8px',
    backgroundColor: 'rgba(243, 244, 246, 0.3)',
    width: '90%',
    margin: 'auto',
    padding: '10px',
  },
  contentText: {
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '18px',
      paddingBottom: '18px',
    },
  },
  approveButtonLayout: {
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5px',
      paddingBottom: '10px',
    },

  }
}));

const Migrator: React.FC = () => {
  const { account } = useWallet();
  const classes = useStyles();
  const [displayAeraBalance, setDisplayAeraBalance] = useState<string>('0.0');
  const [displayAeraFractalBalance, setDisplayAeraFractalBalance] = useState<string>('0');

  const tombFinance = useTombFinance();

  const aeraBalance = useTokenBalance(tombFinance?.WPLS);
  const aeraFractalBalance = useTokenBalance(tombFinance?.AERAFRACTALV1);
  const [approveAeraStatus, approveAera] = useApprove(tombFinance?.WPLS, tombFinance?.contracts['TokenSwap'].address);

  const [approveAeraFractalStatus, approveAeraFractal] = useApproveNFT(
    tombFinance?.contracts['aeraFractalV1'],
    tombFinance?.contracts['fractalMigrator'].address,
  );

  const { onSwapAERAv1ToAERAv2 } = useTokenSwap();
  const { onSwapNFT } = useSwapNFT(tombFinance?.contracts['aeraFractalV1'], tombFinance?.contracts['fractalMigrator']);


  const frmtAera = useMemo(() => getDisplayBalance(aeraBalance), [aeraBalance]);
  const handleAeraMaxEvent = () => {
    setDisplayAeraBalance(frmtAera);
  };

  const handleAeraChange = (event: any) => {
    setDisplayAeraBalance(event.target.value);
  };

  const gradientLeftColor =
    localStorage.getItem('theme-aera') === 'theme-aera-day'
      ? 'linear-gradient(90deg, #B57303 22.92%, #ffffff 40.33%, #ffffff 59.81%, #FFFFFF 78.25%)'
      : 'linear-gradient(90deg, #B57303 22.92%, #ffffff 40.33%, #ffffff 59.81%, #FFFFFF 78.25%)';
  const gradientRightColor =
    localStorage.getItem('theme-aera') === 'theme-aera-day'
      ? 'linear-gradient(90deg, #B57303 0%, #ffffff 31.46%, #ffffff 66.67%, #FFFFFF 100%)'
      : 'linear-gradient(90deg, #B57303 0%, #ffffff 31.46%, #ffffff 66.67%, #FFFFFF 100%)';

  return (
    <Page>
      <BackgroundImage />
      {!!account ? (
        <>
          <Grid container spacing={2} className={classes.container} style={{paddingTop: "40px"}}>
            <Grid item xs={12} md={12}>
              <Typography
                className={cx(
                  classes.headerTitle,
                  localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white',
                )}
              >
                Welcome to our ICO Page!
              </Typography>
              <Typography
                className={cx(
                  classes.headerTitle,
                  localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white',
                )}
              >
                Where you have the opportunity to acquire FLUX tokens at an exclusive presale price. The highly anticipated ICO will take place from May 26, 2023, at 23:00 GMT-3, until May 29, 2023, at 23:00 GMT-3. The funds raised during the ICO will be allocated towards marketing efforts and adding initial liquidity to enhance the project's success.
              </Typography>
              <Typography
                className={cx(
                  classes.headerTitle,
                  localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white',
                )}
              >
                During the presale period, each FLUX token can be purchased for just 5000 PSL, offering an incredible value. As an added benefit, on May 29th, we will introduce liquidity to the market, setting the price of each FLUX token at 6000 PSL.
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid
                item
                className={cx(
                  localStorage.getItem('theme-aera') === 'theme-aera-day'
                    ? 'gradient-day-border'
                    : 'gradient-night-border',
                  classes.contenBlockTop,
                )}
              >
                <Grid
                  container
                  style={{
                    alignItems: 'center',
                    paddingLeft: '16px',
                    borderBottom: '1px solid #ffffff',
                  }}
                  className={localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white'}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.contentHeader}>
                    <span style={{ marginRight: '5px' }}>Get FLUX tokens</span>
                    <img src={redLogo} alt="Logo" loading="lazy" height={32} />
                  </Grid>
                </Grid>

                <Grid
                  container className={cx(classes.contentText, localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white')}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={cx(classes.contentTitle)}
                    style={{ backgroundImage: gradientLeftColor }}
                  >
                    <span>1 FLUX = 5000 PSL</span>
                  </Grid>
                </Grid>
                <Grid container className={cx(classes.balanceBox, localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white')}>
                  <Grid item xs={6} md={6} lg={6} className={cx(classes.contentBody, classes.leftedText)}>
                    <label>From</label>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} className={cx(classes.contentBody, classes.rightedText)}>
                    <span>Balance: {frmtAera}</span>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} className={cx(classes.contentBody, classes.leftedText)}>
                    <label>
                      {localStorage.getItem('theme-aera') === 'theme-aera-day' ? (
                        <CustomBlackTextField
                          placeholder="0.0"
                          defaultValue={displayAeraBalance}
                          value={displayAeraBalance}
                          onChange={handleAeraChange}
                        />
                      ) : (
                        <CustomWhiteTextField
                          placeholder="0.0"
                          defaultValue={displayAeraBalance}
                          value={displayAeraBalance}
                          onChange={handleAeraChange}
                        />
                      )}
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    className={cx(classes.contentBody, classes.centeredText)}
                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <Button
                      className={cx(
                        classes.maxButton,
                        localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white',
                      )}
                      onClick={handleAeraMaxEvent}
                    >
                      MAX:
                    </Button>
                    <img
                      className={classes.lazyLogo}
                      src={redLogo} alt="Logo" loading="lazy" />
                    <span> WPSL</span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={cx(classes.approveButtonLayout, localStorage.getItem('theme-aera') === 'theme-aera-day' ? 'text-black' : 'text-white')}
                >
                  <Grid item xs={12} md={12} lg={12}>
                    {localStorage.getItem('theme-aera') === 'theme-aera-day' ? (
                      approveAeraStatus !== ApprovalState.APPROVED ? (
                        <BlackBorderButton
                          style={{
                            textTransform: 'none'
                          }}
                          disabled={approveAeraStatus !== ApprovalState.NOT_APPROVED}
                          onClick={() => approveAera(displayAeraBalance)}
                        >
                          Approve
                        </BlackBorderButton>
                      ) : (
                        <BlackBorderButton
                          style={{
                            textTransform: 'none'
                          }}

                          onClick={() => onSwapAERAv1ToAERAv2(displayAeraBalance.toString())}>
                          Convert
                        </BlackBorderButton>
                      )
                    ) : approveAeraStatus !== ApprovalState.APPROVED ? (
                      <WhiteBorderButton
                        style={{
                          textTransform: 'none'
                        }}

                        disabled={approveAeraStatus !== ApprovalState.NOT_APPROVED}
                        onClick={() => approveAera(displayAeraBalance)}
                      >
                        Approve
                      </WhiteBorderButton>
                    ) : (
                      <WhiteBorderButton
                        style={{
                          textTransform: 'none'
                        }}
                        onClick={() => onSwapAERAv1ToAERAv2(displayAeraBalance.toString())}>
                        Convert
                      </WhiteBorderButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <UnlockWallet />
      )
      }
    </Page >
  );
};

const BackgroundImage = createGlobalStyle`
      body {
  ${localStorage.getItem('theme-aera') === 'theme-aera-day' &&
  `
  background: #D0D0D0;
  `
  }
      ${localStorage.getItem('theme-aera') === 'theme-aera-night' &&
  `
  background: #000000;
  `
  }
      background - size: cover!important;
}
`;

export default Migrator;
