import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// const Theme3 = React.lazy(() => import('./Theme3'));
// const Theme4 = React.lazy(() => import('./Theme4'));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem('theme-aera');
  return (
    <>
      <React.Suspense fallback={<></>}>
        {/* {(CHOSEN_THEME === 'theme-aera-3') && <Theme3 />} */}
        {/* {(CHOSEN_THEME === 'theme-aera-4') && <Theme4 />} */}
      </React.Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <ThemeSelector>
    <App />
  </ThemeSelector>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
