import React from 'react';

//Graveyard ecosystem logos
import tombLogo from '../../assets/img/scarab-logo.png';
import tShareLogo from '../../assets/img/aerium-logo.png';
import tombLogoPNG from '../../assets/img/scarab-logo.png';
import tShareLogoPNG from '../../assets/img/aerium-logo.png';
import tBondLogo from '../../assets/img/sbond-logo.png';

import tombtombLogo from '../../assets/img/crypto_tomb_cash.svg';
import tSharetShareLogo from '../../assets/img/crypto_tomb_share.svg';
import tombFtmLpLogo2 from '../../assets/img/tomb_ftm_lp.png';
import tshareFtmLpLogo2 from '../../assets/img/tshare_ftm_lp.png';
import spiritFtmLpLogo2 from '../../assets/img/spirit_ftm_lp.png';
import lqdrFtmLpLogo2 from '../../assets/img/lqdr_ftm_lp.png';

import tombFtmLpLogo from '../../assets/img/scarab_ftm_lp.png';
import tshareFtmLpLogo from '../../assets/img/gscarab_ftm_lp.png';

import hermesusdcLpLogo from '../../assets/img/lpimages/HERMESMETIS_LP.png';
import usdcusdtLpLogo from '../../assets/img/lpimages/USDCUSDT_LP.png';
import wbtcusdcLpLogo from '../../assets/img/lpimages/WBTCUSDC_LP.png';
import wethusdcLpLogo from '../../assets/img/lpimages/ETHUSDC_LP.png';
import aerausdcLpLogo from '../../assets/img/lpimages/AERAUSDC_LP.png';
import aeraLogo from '../../assets/img/tokensTulip/arerarium.png'


import wftmLogo from '../../assets/img/ftm_logo_blue.png';
import booLogo from '../../assets/img/spooky.png';
import zooLogo from '../../assets/img/zoo_logo.svg';
import shibaLogo from '../../assets/img/shiba_logo.svg';
import spiritLogo from '../../assets/img/spirit-logo.png';
import sinSpiritLogo from '../../assets/img/sinSpirit-logo.png';

import fusdtLogo from '../../assets/img/fusdt-logo.png';
import usdcLogo from '../../assets/img/usdc-logo.png';
import btcLogo from '../../assets/img/btc-logo.png';
import ethLogo from '../../assets/img/eth-logo.png';

import fraxLogo from '../../assets/img/eth-logo.png';
import maiLogo from '../../assets/img/eth-logo.png';
import daiLogo from '../../assets/img/eth-logo.png';

import fusdtFtmLpLogo2 from '../../assets/img/fusdt_ftm_lp.png';
import usdcFtmLpLogo2 from '../../assets/img/usdc_ftm_lp.png';
import btcFtmLpLogo2 from '../../assets/img/btc_ftm_lp.png';
import ethFtmLpLogo2 from '../../assets/img/eth_ftm_lp.png';
import spiritSinspiritLpLogo2 from '../../assets/img/spirit_sinspirit_lp.png';

import fraxFtmLpLogo2 from '../../assets/img/frax_ftm_lp.png';
import maiFtmLpLogo2 from '../../assets/img/mai_ftm_lp.png';

import creatorFtmLpLogo2 from '../../assets/img/cre8r_ftm_lp.png';
import creatorLogo from '../../assets/img/cre8r-logo.png';

import lpLogoBOMBFTMLP from '../../assets/img/bomb_ftm_lp.png'
import lpLogoBIFIFTMLP from '../../assets/img/bifi_ftm_lp.png'
import lpLogoDEIUSDCLP from '../../assets/img/dei_usdc_lp.png'
import lpLogogOHMFTMLP from '../../assets/img/gohm_ftm_lp.png'
import lpLogoATLASFTMLP from '../../assets/img/atlas_ftm_lp.png'
import lpLogoSCARABGSCARABLP from '../../assets/img/scarab_gscarab_lp.png'
import lpLogoDEUSFTMLP from '../../assets/img/deus_ftm_lp.png'

import bombLogo from '../../assets/img/bomb-logo.webp'
import bifiLogo from '../../assets/img/bifi-logo.webp'
import deiLogo from '../../assets/img/dei.webp'
import gohmLogo from '../../assets/img/gohm.webp'
import atlasLogo from '../../assets/img/atlas-logo.webp'
import deusLogo from '../../assets/img/deus.webp'
import HLPUSDCLogo from '../../assets/img/hlp-m.usdc.svg'
import HLPUSDTLogo from '../../assets/img/hlp-m.usdt.svg'
import HLPDAILogo from '../../assets/img/hlp-m.dai.svg'

import FLUXWPLSLogo from '../../assets/img/fluxwpls.png'
import PLSXWPLSLogo from '../../assets/img/plsxwpls.png'
import DAIWPLSLogo from '../../assets/img/daiwpls.png'
import USDCWPLSLogo from '../../assets/img/usdcwpls.png'
import HEXWPLSLogo from '../../assets/img/hexwpls.png'
import WETHWPLSLogo from '../../assets/img/wethwpls.png'
import USDTWPLSLogo from '../../assets/img/usdtwpls.png'

import spookyswapLogo from '../../assets/img/spookyswap-logo.png'
import spiritswapLogo from '../../assets/img/spiritswap-logo.png'
import { withStyles, makeStyles } from '@material-ui/core/styles';


const logosBySymbol: { [title: string]: string } = {
  //Real tokens
  //=====================
  SCARAB: tombLogo,
  SCARABPNG: tombLogoPNG,
  TSHARE: tSharetShareLogo,
  LQDR: tSharetShareLogo,
  'CRE8R': creatorLogo,
  WPLS: tombtombLogo,
  GSCARABPNG: tShareLogoPNG,
  GSCARAB: tShareLogo,
  SBOND: tBondLogo,
  WFTM: wftmLogo,
  BOO: booLogo,
  SHIBA: shibaLogo,
  ZOO: zooLogo,
  SPIRIT: spiritLogo,
  SinSPIRIT: sinSpiritLogo,
  fUSDT: fusdtLogo,
  USDC: usdcLogo,
  BTC: btcLogo,
  ETH: ethLogo,
  FRAX: fraxLogo,
  MAI: maiLogo,
  DAI: daiLogo,

  ARA: aeraLogo,
  HERMES: daiLogo,
  WBTC: btcLogo,
  WETH: ethLogo,
  'ARA-USDC-LP': aerausdcLpLogo,
  'HERMES-METIS-LP': hermesusdcLpLogo,
  'WETH-USDC-LP': wethusdcLpLogo,
  'WBTC-USDC-LP': wbtcusdcLpLogo,
  'USDC-USDT-LP': usdcusdtLpLogo,

  'SCARAB-FTM-LP': tombFtmLpLogo,
  'GSCARAB-FTM-LP': tshareFtmLpLogo,
  'WPLS-FTM-LP': tombFtmLpLogo2,
  'TSHARE-FTM-LP': tshareFtmLpLogo2,
  'LQDR-FTM-LP': lqdrFtmLpLogo2,
  'CRE8R-FTM-LP': creatorFtmLpLogo2,
  'SPIRIT-SINSPIRIT-LP': spiritSinspiritLpLogo2,
  'SPIRIT-FTM-LP': spiritFtmLpLogo2,
  'fUSDT-FTM-LP': fusdtFtmLpLogo2,
  'USDC-FTM-LP': usdcFtmLpLogo2,
  'BTC-FTM-LP': btcFtmLpLogo2,
  'ETH-FTM-LP': ethFtmLpLogo2,
  'FRAX-FTM-LP': fraxFtmLpLogo2,
  'MAI-FTM-LP': maiFtmLpLogo2,

  'HLP-m.USDC': HLPUSDCLogo,
  'HLP-m.USDT': HLPUSDTLogo,
  'HLP-m.DAI': HLPDAILogo,

  'FLUX-WPLS': FLUXWPLSLogo,
  'PLSX-WPLS': PLSXWPLSLogo,
  'DAI-WPLS': DAIWPLSLogo,
  'USDC-WPLS': USDCWPLSLogo,
  'HEX-WPLS': HEXWPLSLogo,
  'WETH-WPLS': WETHWPLSLogo,
  'USDT-WPLS': USDTWPLSLogo,




  BOMB: bombLogo,
  BIFI: bifiLogo,
  DEI: deiLogo,
  gOHM: gohmLogo,
  ATLAS: atlasLogo,
  DEUS: deusLogo,
  'BOMB-FTM-LP': lpLogoBOMBFTMLP,
  'BIFI-FTM-LP': lpLogoBIFIFTMLP,
  'DEI-USDC-LP': lpLogoDEIUSDCLP,
  'gOHM-FTM-LP': lpLogogOHMFTMLP,
  'ATLAS-FTM-LP': lpLogoATLASFTMLP,
  'SCARAB-GSCARAB-LP': lpLogoSCARABGSCARABLP,
  'DEUS-FTM-LP': lpLogoDEUSFTMLP,

  'SPOOKYSWAP': spookyswapLogo,
  'SPIRITSWAP': spiritswapLogo,
};

type LogoProps = {
  symbol: string;
  size?: number;
  x?: number;
};

const useStyles = makeStyles((theme) => ({
  imgSize: {
    [theme.breakpoints.down('xs')]: {
      width: '42px',
      height: '21px'
    }
  },

}));
const CustomSizeLpSymbol: React.FC<LogoProps> = ({ symbol }) => {
  const classes = useStyles();
  if (!logosBySymbol[symbol]) {
    throw new Error(`Invalid Token Logo symbol: ${symbol}`);
  }
  return <img className={classes.imgSize} src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={32 * 2} height={32} />;
};

export default CustomSizeLpSymbol;
