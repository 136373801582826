import React, { useEffect, useMemo, useState } from 'react';
import WalletCard from './WalletCard';
import { Modal, List, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWallet } from 'use-wallet';
import useTombFinance from '../../hooks/useTombFinance';
import TokenSymbol from '../TokenSymbol';
import ModalTitle from '../ModalTitle';
import useTokenBalance from '../../hooks/useTokenBalance';
import { getDisplayBalance } from '../../utils/formatBalance';
import accountAeriumIcon from './../../assets/img/aerium-logo.png'
import copyIconDay from './../../assets/copy-icon-day.svg'
import copyIconNight from './../../assets/copy-icon-night.svg'
import disconnectIcon from './../../assets/disconnect-icon.svg'
import useContractLockedSum from '../../hooks/useContractLockedSum';
import useStakedNftsNumber from '../../hooks/useStakeNftsNumber';
// import { shortenAddress } from "@usedapp/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '356px',
    maxWidth: '100%',
    backgroundColor: '#D0D0D0',
    backdropFilter: 'blur(3.5px)',
    color: '#7A7A7A',
    // boxShadow: theme.shadows[5],
    padding: '11px',
    outline: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '15px',


    background: 'linear-gradient(#000000, #000000) padding-box, linear-gradient(to right, rgb(24, 33, 47, 0.8), rgb(250, 250, 250, 0.8)) border-box',
    border: '3px solid transparent',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pt6: {
    paddingTop: '6px'
  },
  pt21: {
    paddingTop: '21px'
  },
  accountText: {
    fontSize: '20px'
  },
  tokenInfoCopyIcon: {
    width: '17px',
    height: '17px',
    content: `url(${localStorage.getItem('theme-aera') === 'theme-aera-day' ? copyIconDay : copyIconNight})`,
  },
  disconnectIcon: {
    width: '17px',
    height: '17px',
    content: `url(${disconnectIcon})`,
  },
  button: {
    background: 'linear-gradient(#000000, #000000) padding-box, linear-gradient(to right, rgb(24, 33, 47, 0.8), rgb(250, 250, 250, 0.8)) border-box',
    border: '2px solid transparent',
    borderRadius: '15px',
    height: '45px',
    width: '123px',
    display: 'flex',
    padding: '0px',
    textTransform: 'none'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    fontWeight: 700,
    fontSize: '13px',
    fontFamily: 'Poppins',
    color: '#7A7A7A',
    paddingRight: '5px'
  }
}));

const AccountModal = ({ open, handleClose }) => {
  const [accountText, setAccountText] = useState("");
  const classes = useStyles();
  const { account, connect, reset } = useWallet();
  const tombFinance = useTombFinance();
  useEffect(() => {
    if (account) {
      convertAccountText(account);
    }
  })

  const convertAccountText = (account) => {
    const accountShortened = `${account.substring(0, 4)}...${account.substring(account.length - 4)}`;
    setAccountText(accountShortened);
  }

  const lockedAmount = useContractLockedSum(tombFinance?.contracts['AeraLocker']);
  const areaBalance = useTokenBalance(tombFinance?.ARA);
  const stakeCount = useStakedNftsNumber(tombFinance?.AERAFRACTALV2);
  const vIAreaBalance = useTokenBalance(tombFinance?.vIAREA);
  const areaFractalBalance = useTokenBalance(tombFinance?.AERAFRACTALV2);

  const displayIAreaBalance = useMemo(() => getDisplayBalance(vIAreaBalance), [vIAreaBalance]);
  const displayAreaBalance = useMemo(() => getDisplayBalance(areaBalance), [areaBalance]);
  const displayAreaFractalBalance = Number(areaFractalBalance)+Number(stakeCount);

  const humBalance = useTokenBalance(tombFinance?.aeHUM);
  const humAera = useMemo(() => getDisplayBalance(humBalance), [humBalance]);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(account)
      .then(() => {
        console.log("Text copied to clipboard!");
      })
      .catch(err => {
        console.error("Error copying text: ", err);
      });
  }

  const handleDisconnect = () => {
    handleClose();
    reset();
    localStorage.setItem('connectorId', '');
  }
  return (
    <Modal
      aria-labelledby="connect a wallet"
      aria-describedby="connect your crypto wallet"
      open={open}
      className={classes.modal}
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <div>
          <img src={accountAeriumIcon} width="72px" height="72px" />
        </div>
        <div style={{
          display: 'inline-flex'
        }}>
          <span className='gradient-account-text'
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >{accountText}</span>
        </div>
        <div className={classes.pt6}>
          <span>{displayAreaBalance} FLUX</span>
        </div>
        <div className={classes.pt21}
          style={{
            display: 'inline-flex'
          }}
        >
          <Button className={classes.button}
            onClick={handleClickCopy}
          >
            <div className={classes.flexCenter}>
              <span className={classes.buttonText}>Copy Address</span>
              <img className={classes.tokenInfoCopyIcon} />
            </div>
          </Button>
          <Button className={classes.button}
            onClick={handleDisconnect}
            style={{
              marginLeft: '20px'
            }}
          >
            <div className={classes.flexCenter}>
              <span className={classes.buttonText}>Disconnect</span>
              <img className={classes.disconnectIcon} />
            </div>
          </Button>
        </div>
      </div >
    </Modal >
  );
};

export default AccountModal;
