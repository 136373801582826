import { useCallback, useEffect, useState } from 'react';
import { BigNumber, Contract } from 'ethers';
import useTombFinance from './useTombFinance';
import config from '../config';
import useTokenIdByOwner from './useTokenIdByOwner';

const useGetApproveAddr = (contract: Contract, status?: boolean) => {
  const [approveAddr, setApproveAddr] = useState('');
  const tombFinance = useTombFinance();
  const tokenId = useTokenIdByOwner(tombFinance?.contracts['aeraFractalV1']);

  const isUnlocked = tombFinance?.isUnlocked;
  const fetchTokenId = useCallback(async () => {
    if (tokenId != BigNumber.from(0)) {
      setApproveAddr(await contract.getApproved(tokenId));
    }
  }, [contract, tombFinance, tokenId, status]);

  useEffect(() => {
    if (isUnlocked) {
      fetchTokenId().catch((err) => console.error(`Failed to fetch contract balance 1: ${err.stack}`));
      let refreshInterval = setInterval(fetchTokenId, config.refreshInterval);
      return () => clearInterval(refreshInterval);

    }
  }, [isUnlocked, contract, fetchTokenId, tombFinance]);

  return approveAddr;
};

export default useGetApproveAddr;
