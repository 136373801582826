import { useCallback, useMemo, useState } from 'react';
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks';
import useAllowance from './useAllowance';
import ERC20 from '../tomb-finance/ERC20';
import { parseEther } from 'ethers/lib/utils';
import useTombFinance from './useTombFinance';

const APPROVE_BASE_AMOUNT = parseEther('0.0001');

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApprove(token: ERC20, spender: string): [ApprovalState, (amount: string) => Promise<void>] {
  const pendingApproval = useHasPendingApproval(token?.address, spender);
  const [successState, setSuccessState] = useState(false);
  const currentAllowance = useAllowance(token, spender, successState);

  const tombFinance = useTombFinance();

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;
    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(APPROVE_BASE_AMOUNT)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [currentAllowance, pendingApproval, successState]);

  const addTransaction = useTransactionAdder();

  const approve = useCallback(
    async (amount: string): Promise<void> => {
      // if (approvalState !== ApprovalState.NOT_APPROVED) {
      //   console.error('approve was called unnecessarily');
      //   return;
      // }
      const response = await token.approve(spender, parseEther(amount));

      if (response) {
        await tombFinance.contracts['aeHum'].provider.waitForTransaction(response.hash);
        setSuccessState(!successState);
      }

      addTransaction(response, {
        summary: `Approve ${token.symbol}`,
        approval: {
          tokenAddress: token.address,
          spender: spender,
        },
      });
    },
    [approvalState, token, spender, addTransaction],
  );

  return [approvalState, approve];
}

export default useApprove;