import { useCallback, useEffect, useState } from 'react';
import { BigNumber, Contract } from 'ethers';
import useTombFinance from './useTombFinance';
import config from '../config';
import { getDisplayBalance } from './../utils/formatBalance';

const useStakedNftsNumber = (token: Contract) => {
  const [balance, setBalance] = useState(BigNumber.from(0));
  const [tokenList, setTokenList] = useState(0);

  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;
  const fetchBalance = useCallback(async () => {
    if (token) {
      const myWallet = tombFinance?.myAccount;
      const count = Number(await token.balanceOf(myWallet));
      const stakeCount = Number(await tombFinance.contracts['FractalEmissionDistributor'].totalNftsByUser(myWallet));
      setTokenList(stakeCount);
    }
  }, [token, tombFinance?.myAccount, setTokenList]);

  useEffect(() => {
    if (isUnlocked) {
      fetchBalance().catch((err) => console.error(`Failed to fetch token balance: ${err.stack}`));
      let refreshInterval = setInterval(fetchBalance, config.refreshInterval);
      return () => clearInterval(refreshInterval);
    }
  }, [isUnlocked, token, fetchBalance, tombFinance]);

  return tokenList;
};

export default useStakedNftsNumber;
