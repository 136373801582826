import { useCallback } from 'react';
import useTombFinance from './useTombFinance';
import { Bank } from '../tomb-finance';
import useHandleTransactionReceipt from './useHandleTransactionReceipt';
import { parseUnits } from 'ethers/lib/utils';
import { BigNumber, Contract, ethers } from 'ethers';
import useTokenIdByOwner from './useTokenIdByOwner';

const useSwapNFT = (contractV1: Contract, fractalMigrator: Contract) => {
  const tombFinance = useTombFinance();
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const tokenId = useTokenIdByOwner(contractV1);
  const handleStake = useCallback(
    () => {
      handleTransactionReceipt(
        fractalMigrator.swap([tokenId]),
        `Swap ${Number(tokenId)} AeraNFT for a new AraFractal`,
      );
    },
    [tombFinance, handleTransactionReceipt, tokenId]
  );
  return { onSwapNFT: handleStake };
};

export default useSwapNFT;