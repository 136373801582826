import React from 'react';
import clsx from 'clsx';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Button,
  Container,
  Grid,
} from '@material-ui/core';

import ListItemLink from '../ListItemLink';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountButton from './AccountButton';
import AccountModal from '../AccountModal';
import styled from 'styled-components';

import GScarabLogo from '../../assets/img/aerium-logo.png';
import ThemeButton from '../ThemeModal/ThemeButton';
import { white } from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    color: localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#fcfcfc' : '#fcfcfc',
    // 'background': localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#131313' : '#131313',
    background: 'transparent',
    padding: '0px',
    marginBottom: '0rem',
    borderBottom: '1px solid #E2E8F0',
    height: '97px',
    [theme.breakpoints.down('xs')]: {
      height: '54px',
    },
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    backgroundColor: 'rgb(255, 255, 255, 0.47)',
    backdropFilter: 'blur(5.5px)',
  },
  drawerPaper: {
    width: 240,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    flexWrap: 'wrap',
    height: '100% !important',
    gridTemplateColumns: '17.5% 55 % 27.5 %',
  },
  toolbarTitle: {
    fontFamily: '"Poppins", cursive',
    fontSize: '30px',
    flexGrow: 'initial',
  },
  toolbarCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  toolbarRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'right',
  },

  link: {
    fontFamily: "Poppins !important",
    textTransform: 'capitalize',
    color: localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#334155' : '#AAAAAA',
    fontSize: '18px',
    margin: theme.spacing(1, 2),
    fontStyle: 'normal',
    // fontWeight: 500,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  brandLink: {
    textDecoration: 'none',
    color: localStorage.getItem('theme-aera') === 'theme-aera-day' ? '#fcfcfc' : '#fcfcfc',
    paddingLeft: '9px',
    paddingTop: '22px',
    paddingBottom: '18px',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
}));

const StyledLink = styled(Link)<{ active: boolean }>(({ active }) => ({
  color: active
    ? localStorage.getItem('theme-aera') === 'theme-aera-day'
      ? '#000000 !important'
      : '#FFFFFF !important'
    : '',
  // margin: '0px 12px',
  marginLeft: '12px !important',
  marginRight: '12px !important',
  padding: '0px 0px 7px',
  fontSize: '18px !important',
}));

const StyledToolbar = styled(Toolbar)`
  // display: grid !important;
  // grid-template-columns: 22.5% 55% 22.5%;
  padding-left: 13px !important;
  padding-right: 13px !important;
  height: 60px !important;
  // border-bottom: 2px solid rgba(133, 133, 133, 0.1) !important;
  transform: translate3d(0px, 0px, 0px) !important;
  color: #fcfcfc !important;
  [theme.breakpoints.down('xs')]: {
  },
`;

const StyledMenuTop = styled(Box)`
  margin-right: 0px !important;
  justify-self: center;
  color: #fcfcfc !important;
  font-weight: 600;
`;

const StyledIconButton = styled(IconButton)`
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledBrand = styled(IconButton)`
  ${localStorage.getItem('theme-aera') === 'theme-aera-day' &&
  `
color: #212121 !important;
`}
  ${localStorage.getItem('theme-aera') === 'theme-aera-night' &&
  `
color: #fcfcfc !important;
`}
display: flex;
  align-items: center;
`;

const Nav = () => {
  const matches = useMediaQuery('(min-width:900px)');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { path } = useRouteMatch();
  const [isAccountModalOpen, setAccountModalOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLinkLandingPage = () => {
    window.location.href = 'https://aerarium-frontend-beta.vercel.app';
  };

  return (
    <AppBar position="relative" elevation={0} className={classes.appBar}>
      <div>
        <StyledToolbar className={classes.toolbar}>
          {matches ? (
            <>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                    <StyledBrand
                      color="inherit"
                      className={classes.brandLink}
                      disableRipple
                    >
                      {localStorage.getItem('theme-aera') === 'theme-aera-day' ? (
                        <img src={GScarabLogo} style={{ marginRight: '10px' }} width={54} height={54} />
                      ) : (
                        <img src={GScarabLogo} style={{ marginRight: '10px' }} width={54} height={54} />
                      )}
                      {/* FluxZone */}
                    </StyledBrand>
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.toolbarCenter}>
                  <StyledMenuTop mr={1}>
                     <StyledLink active={path === '/'} color="textPrimary" to="/" className={classes.link}>
                Home
              </StyledLink>
                    <StyledLink active={path === '/farms'} color="textPrimary" to="/farms" className={classes.link}>
                      Farms
                    </StyledLink>
                    <StyledLink
                      active={path === '/migrator'}
                      color="textPrimary"
                      to="/migrator"
                      className={classes.link}
                    >
                      ICO
                    </StyledLink>
                  </StyledMenuTop>
                </Grid>
                <Grid item xs={4} className={classes.toolbarRight}>
                  <StyledDiv style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
               {/*     <ThemeButton /> */}
                    <AccountButton text="Connect" />
                  </StyledDiv>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container>
              {/* <StyledIconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(open && classes.hide)}
              > */}
              <Grid
                item
                xs={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <MenuIcon onClick={handleDrawerOpen} className={clsx(open && classes.hide)} />
                <img src={GScarabLogo} style={{ marginLeft: '10px' }} width={38} height={38} />
              </Grid>
              <Grid item xs={7}>
                <StyledDiv style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
               {/*     <ThemeButton /> */}
               <AccountButton text="Connect" />
                </StyledDiv>
              </Grid>

              <Drawer
                className={classes.drawer}
                onClose={handleDrawerClose}
                // onEscapeKeyDown={handleDrawerClose}
                // onBackdropClick={handleDrawerClose}
                variant="temporary"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div>
                  <IconButton onClick={handleDrawerClose}>
                    <img src={GScarabLogo} style={{ marginRight: '10px' }} width={38} height={38} />
                    <span
                      style={{
                        color: '#FFFFFF',
                        fontSize: '20px',
                      }}
                    >
                      FluxZone
                    </span>

                    {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                  </IconButton>
                </div>
                {/* <Divider /> */}
                <List
                  style={{
                    paddingTop: '25px',
                    display: 'contents',
                  }}
                >
                  <StyledLink
                    style={{
                      paddingTop: '20px',
                    }}
                    active={path === '/farms'}
                    color="textPrimary"
                    to="/farms"
                    className={classes.link}
                  >
                    Farms
                  </StyledLink>
                   {/* <ListItemLink primary="Fractals" to="/fractals" />
                  <ListItemLink primary="Farms" to="/farms" />
                  <ListItemLink primary="Governance" to="/governance" />
                  <ListItemLink primary="Migrator" to="/migrator" /> */}
                </List>
              </Drawer>
            </Grid>
          )}
        </StyledToolbar>

      </div>
    </AppBar>
  );
};

const StyledDiv = styled(Box)`
  justify-content: flex-end;
`;

export default Nav;
