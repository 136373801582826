import React, { useCallback, useEffect, useState } from 'react';
import Context from './context';
import useTombFinance from '../../hooks/useTombFinance';
import { AutoCompounder } from '../../tomb-finance';
import config, { autoCompounderDefinitions } from '../../config';

const AutoCompounders: React.FC = ({ children }) => {
  const [autoCompounders, setAutoCompounders] = useState<AutoCompounder[]>([]);
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;

  const fetchPools = useCallback(async () => {
    const autoCompounders: AutoCompounder[] = [];

    for (const bankInfo of Object.values(autoCompounderDefinitions)) {
      if (bankInfo.finished) {
        if (!tombFinance.isUnlocked) continue;

        // only show pools staked by user
        const balance = await tombFinance.stakedBalanceOnAutoCompounder(
          bankInfo.vaultContract,
          tombFinance.myAccount,
        );
        if (balance.lte(0)) {
          continue;
        }
      }
      autoCompounders.push({
        ...bankInfo,
        address: config.deployments[bankInfo.contract].address,
        depositToken: tombFinance.externalTokens[bankInfo.depositTokenName],
        pairTokenContract1: bankInfo.pairTokenName1 === 'SCARAB' || bankInfo.pairTokenName1 === 'GSCARAB' ? (bankInfo.pairTokenName1 === 'SCARAB' ? tombFinance.SCARAB : tombFinance.GSCARAB) : tombFinance.externalTokens[bankInfo.pairTokenName1],
        pairTokenContract2: bankInfo.pairTokenName2 === 'SCARAB' || bankInfo.pairTokenName2 === 'GSCARAB' ? (bankInfo.pairTokenName2 === 'SCARAB' ? tombFinance.SCARAB : tombFinance.GSCARAB) : tombFinance.externalTokens[bankInfo.pairTokenName2],
        earnToken: bankInfo.earnTokenName === 'SCARAB' || bankInfo.earnTokenName === 'SCARAB' ? (bankInfo.earnTokenName === 'SCARAB' ? tombFinance.SCARAB : tombFinance.GSCARAB) : tombFinance.externalTokens[bankInfo.earnTokenName],
        sharesToken: tombFinance.externalTokens[bankInfo.linkVault],
      });
    }
    autoCompounders.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setAutoCompounders(autoCompounders);
  }, [tombFinance, setAutoCompounders]);

  useEffect(() => {
    if (tombFinance) {
      fetchPools().catch((err) => console.error(`Failed to fetch autocompounders: ${err.stack}`));
    }
  }, [isUnlocked, tombFinance, fetchPools]);

  return <Context.Provider value={{ autoCompounders }}>{children}</Context.Provider>;
};

export default AutoCompounders;
